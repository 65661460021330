import './Typography.module.scss';

import Home from './pages/home';
function App() {
  return (
    <div className="App" style={{overflow: "hidden"}}>
      <Home />
    </div>
  );
}

export default App;
